import React from "react"
import { graphql } from "gatsby"
import SmallCardPage from "../../../../components/SmallCardPage"
export default function Community({ data }) {
  return <SmallCardPage data={data}/>
}
export const query = graphql`
  query getEmplyees {
      markdownRemark(frontmatter: {url: {eq: "employees"}}) {
    id
    frontmatter {
      title
      subtitle
      card {
        title
        subtitle
        image
        url
      }
    }
  }
  }
`
